<template>
    <div class="box">
        <Header type="expert" :navList="navList" @search="handleSearchEvent" :typeList="typeList" :navCurrentId="navCurrentId" :logo="logoUrl"></Header>
        <div class="flex-item">
            <div class="container">
                <!-- 面包屑 -->
                <div class="flex pb5 x-left">
                    <div class="color999">您所在的位置：</div>
                    <a-breadcrumb>
                        <a-breadcrumb-item>
                            <router-link class="color999" :to="{path:'/'}">首页</router-link>
                        </a-breadcrumb-item>
                        <a-breadcrumb-item>
                            <router-link class="color999" :to="{path:'/expert/index'}">医咖秀</router-link>
                        </a-breadcrumb-item>
                        <a-breadcrumb-item>专家检索</a-breadcrumb-item>
                    </a-breadcrumb>
                </div>
                <!--    院士风采   -->
                <div class="charming-style mt40">
                    <a-spin :spinning="loading" tip="Loading...">
                        <div class="list-box" v-if="academicianList.length">
                            <expert-list :dataList="academicianList" v-show="!loading"></expert-list>
                        </div>
                        <a-empty v-if="!academicianList.length && !loading" />
                    </a-spin>
                    <div class="t-c mt20">
                        <a-button-group>
                            <a-button @click="handleChangeSize(--paginationParams.page)" v-if="paginationParams.page != 1" type="primary">
                                <a-icon type="left" />上一页 </a-button>
                            <a-button @click="handleChangeSize(++paginationParams.page)" v-if="paginationParams.pageSize <= academicianList.length" type="primary"> 下一页
                                <a-icon type="right" />
                            </a-button>
                        </a-button-group>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import { expertNavList, exportLogo } from "@/config/const";
import Header from '@/components/layout/layout-header';
import Footer from '@/components/layout/layout-footer';
import expertList from '@/components/expert-list'
import { jsonFormat } from "@/utils/jsonFormat";

export default {
    name: "ExpertSearch",
    components: {
        Header, expertList, Footer
    },
    data() {
        return {
            logoUrl: exportLogo,
            navList: expertNavList,//顶部菜单
            navCurrentId: 2,//当前页面菜单对应下标
            academicianList: [],
            loading: true,
            paginationParams: {
                page: 1, //页数
                pageSize: 12, //当前页展示多少
                total: 0, // 数据总量
            },
            typeList: [
                { id: 6, name: '全部', path: '/expert/user-search', selected: true },
                { id: 7, name: '院士', path: '/expert/ys-mien' },
                { id: 8, name: '主委', path: '/expert/committee' },
                { id: 9, name: '关注', path: '/expert/follow' },
            ]
        }
    },
    created() {
        this.getList();
    },

    // 缓存页面二次进入的钩子函数
    // activated() {
    //     this.getList();
    // },
    methods: {
        handleSearchEvent(search_name) {
            this.paginationParams.page = 1
            this.getList()
        },
        getList() {
            let { page, pageSize } = this.paginationParams;
            this.loading = true;
            let data = { limit: pageSize, page }
            let { search_name } = this.$route.query
            search_name && (data['name'] = search_name);
            this.request.post('ExpertsListV3', data).then(res => {
                let formatTemplate = {
                    name: 'real_name',
                    subject: 'department',
                    company: 'org_cnname',
                    avatar: 'avatar',
                    introduce: 'resume',
                    user_id: 'user_id'
                }
                let data = res.data.list;
                this.paginationParams.total = parseInt(res.data.count);
                this.academicianList = jsonFormat(data, formatTemplate);
                this.loading = false;
            })
        },
        handleChangeSize(page) {
            this.paginationParams.page = page;
            this.getList();
        }
    }
}
</script>

<style scoped lang="less">
.list-box {
    min-height: 500px;
}
@media screen and (max-width: 768px) {
    .container {
        padding: 10px;
    }
    /deep/.unlogin-header,
    /deep/.login-header {
        right: 30px !important;
    }
}
</style>